export const fbtztgColumn = [
  {
    prop: "index",
    label: "序号",
    width: 80,
    template: true,
  },
  {
    prop: "title",
    label: "文章标题",
  },
  {
    prop: "createTime",
    label: "发布时间",
  },
  {
    prop: "operation",
    label: "操作",
    width: 180,
    template: true,
  },
]

export const znxxColumn = [
  {
    prop: "index",
    label: "序号",
    width: 80,
    template: true,
  },
  {
    prop: "content",
    label: "标题",
  },
  {
    prop: "sendTime",
    label: "发送时间",
    template: true,
    width: 180,
  },
  {
    prop: "readFlag",
    label: "查阅状态",
    width: 180,
    template: true,
  },
  {
    prop: "operation",
    label: "操作",
    width: 180,
    template: true,
  },
]
